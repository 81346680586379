import React from "react";
import TextPageLayout from "../../components/layouts/TextPageLayout";
// import ParallelDipImage from "../../components/images/ParallelDipImage";
import SEO from "../../components/SEO";
import { Link } from "gatsby";
import { OutboundLink } from "react-ga";
import AccordionGroup from "../../components/base/AccordionGroup";
import { WORKOUT_FORMAT } from "../../models/workouts";
import {
  PullUpBarAffiliateLink,
  RingsAffiliateLink,
  BandsAffiliateLink
} from "../../components/affiliates";
import WorkoutGenerator from "../../components/workoutGenerators/WorkoutGenerator";

function RecommendedRoutine() {
  return (
    <TextPageLayout title="Recommended Routine">
      <SEO
        path="/workouts/recommended-routine/"
        title="Learn the Recommended Routine and create your own calisthenics workout"
        description="Build a strong calisthenics foundation with this easy-to-follow bodyweight fitness routine. Great for beginners and intermediate athletes."
      />
      <p>
        Build a strong calisthenics foundation with this easy-to-follow
        bodyweight fitness routine. Great for beginners and intermediate
        athletes.
      </p>
      <WorkoutGenerator workoutFormat={WORKOUT_FORMAT.recommendedRoutine} />
      <h2>What is Reddit's Recommended Routine?</h2>
      <p>
        A calisthenics workout for all levels. The recommended routine was
        popularised on reddit's{" "}
        <OutboundLink
          eventLabel="to /r/bodyweightfitness"
          to="https://www.reddit.com/r/bodyweightfitness/"
          target="_blank"
        >
          bodyweight fitness
        </OutboundLink>{" "}
        board. The{" "}
        <OutboundLink
          to="https://www.reddit.com/r/bodyweightfitness/wiki/kb/recommended_routine/"
          eventLabel="to /r/bodyweightfitness recommended routine page"
          target="_blank"
        >
          'Recommended Routine'
        </OutboundLink>{" "}
        is named as such because it's an accessible way to learn the fundamental
        bodyweight exercises. For beginners, the recommended routine is also a
        great calisthenics workout to build muscle.
      </p>
      <p>
        The recommended routine is a bodyweight workout designed to target all
        the major muscle groups whilst building the foundations for more
        complicated movements. While some exercises require equipment, like a
        pull-up bar, parallel bars or rings, most have equipment-less
        alternatives.
      </p>
      <p>
        Because each exercise is part of a progression, the routine can easily
        be adjusted to suit your skill level. The Workout Generator creates a
        .pdf of the workout using progress from your{" "}
        <Link to="/skill-tree/">Skill Tree</Link>, so you don't need to worry
        about any mental gymnastics and can focus on the real gymnastics.
      </p>
      <br />
      <div style={{ margin: "0 auto", width: "90%" }}>
        {/* <ParallelDipImage alt="Person performing a parallel bar dip" /> */}
      </div>
      <h2>The Workout Plan</h2>
      <h3>Warm Up Phase</h3>
      <p>
        This part of the workout is focused on dyanamic stretching, joint
        preparation, and practice with the movements.
      </p>
      <ul style={{ listStyle: "circle" }}>
        <li>
          <OutboundLink
            to="https://www.youtube.com/watch?v=Vwn5hSf3WEg"
            eventLabel="Redirect users to Should Prep video"
            target="_blank"
          >
            Shoulder Prep
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            to="https://www.youtube.com/watch?v=lbozu0DPcYI&feature=youtu.be&t=42s"
            eventLabel="Redirect users to Squat Sky Reaches video"
            target="_black"
          >
            Squat Sky Reaches
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            to="https://www.youtube.com/watch?v=mSZWSQSSEjE"
            eventLabel="Redirect users to Wrist Prep video"
            target="_blank"
          >
            Wrist Prep
          </OutboundLink>
        </li>
        <li>
          <OutboundLink
            to="https://www.nick-e.com/deadbug/"
            eventLabel="Redirect users to Deadbugs video"
            target="_black"
          >
            Deadbugs
          </OutboundLink>
        </li>
        <li>Arch Hangs - (after reaching Negative Pull Ups)</li>
        <li>Support Hold - (after reaching Negative Dips)</li>
        <li>Squats - (after reaching Bulgarian Split Squats)</li>
        <li>Romanian Deadlifts - (after reaching 45° Hip Nordic Curl)</li>
      </ul>
      <h3>Strength Phase</h3>
      <p>
        The strength phase consists of 4 groups of exercises. Complete 3 sets of
        each group before moving on to the next one. The Workout Generator will
        let you know which exercises to perform, how many reps to perform, and
        the length of rest between each exercise.
      </p>
      <ol style={{ textAlign: "left" }}>
        <li>Pull Up progression → Squat progression</li>
        <li>Dip progression → Hinge progression</li>
        <li>Row progression → Push Up progression</li>
        <li>
          Anti-extension progression → Anti-rotation progression → Extension
          progression
        </li>
      </ol>
      <p></p>
      <h2>FAQs</h2>
      <AccordionGroup
        accordionData={[
          {
            key: 1,
            title: "What does anti-extension/anti-rotation/extension mean?",
            content: (
              <div>
                <p>These are 3 different categories of core exercises.</p>
                <p>
                  Anti-extension is the name given to exercises like the Plank,
                  where your core resists bending backwards, and remains
                  neutral, while under tension.
                </p>
                <p>
                  Anti-rotation is the name given to core exercises that improve
                  stability against twisting while under tension, this group
                  includes the Paloff Press and Copenhagen Plank.
                </p>
                <p>
                  Extension is the name given to the group of core exercises,
                  like the Hyperextension, which are used to work the back and
                  lower-back.
                </p>
              </div>
            )
          },
          {
            key: 2,
            title: "What equipment do I need?",
            content: (
              <div>
                <ul style={{ listStyle: "circle", paddingLeft: "32px" }}>
                  <li>
                    <PullUpBarAffiliateLink>
                      Pull-Up Bar - (for Pull Up Progressions)
                    </PullUpBarAffiliateLink>
                  </li>
                  <li>
                    <RingsAffiliateLink>
                      Rings, Low Bar - (for Row progressions)
                    </RingsAffiliateLink>
                  </li>
                  <li>Parallel Bar - (for Dip progressions)</li>
                  <li>
                    <BandsAffiliateLink>
                      Resistance Bands - (optional, for modifications)
                    </BandsAffiliateLink>
                  </li>
                </ul>
                <p></p>
              </div>
            )
          },
          {
            key: 3,
            title: "Where can I learn more about the Recommended Routine?",
            content: (
              <p>
                You can find more in-depth information about the Recommended
                Routine on{" "}
                <OutboundLink
                  to="https://www.reddit.com/r/bodyweightfitness/wiki/kb/recommended_routine"
                  eventLabel="Redirect to Reddit"
                  target="_blank"
                >
                  Reddit
                </OutboundLink>
                .
              </p>
            )
          }
        ]}
      />
    </TextPageLayout>
  );
}

export default RecommendedRoutine;
